import React from 'react'

function CallbackPage() {
  return (
    <div className="redirectMessage">
      <p> please wait to be redirected... </p>
    </div>
  )
}

export default CallbackPage
